.search-box {
  position: relative;
  width: 100%;
  font-size: 1.2rem;

  &__input {
    border: 1px solid $color-grey-2;
    border-radius: 3px;
    padding: 5px 3rem;
    font-family: inherit;
    font-size: inherit;
    background-color: $color-grey-3;
    width: 100%;
    text-align: center;
    color: $color-grey-1;

    &:focus ::placeholder {
      visibility: hidden;
    }

    &:focus {
      color: $color-black;
      text-align: left;
      outline: none;
      border: 1px solid $color-grey-1;
    }
  }

  &__placeholder {
    @include absoluteCenter;
    color: transparent;
    pointer-events: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color-grey-1;
    overflow: hidden;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: $color-grey-1;
      position: absolute;
      left: 1rem;
    }

    & > .loader {
      top: unset;
      left: unset;
      right: 0;
      width: 3rem;
      background-color: transparent;
      filter: invert(30%);
    }
  }

  @include respond(phone) {
    height: 65%;
    &__input {
      height: 100%;
      background-color: $color-white;
      border-radius: 5px;
    }
  }
}
